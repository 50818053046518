var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c(
        "div",
        { staticClass: "button-container" },
        [
          _vm.showPreviousButton
            ? _c("previous-button", {
                attrs: { "button-label": _vm.previousButtonLabel },
                on: { click: _vm.previous },
              })
            : _vm._e(),
          _vm.showNextButton
            ? _c("next-button", {
                attrs: {
                  "button-label": _vm.nextButtonLabel,
                  disabled: _vm.disableNextButton,
                },
                on: { next: _vm.emitNext },
              })
            : _vm._e(),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "center-spinner" },
        [_c("ct-centered-spinner")],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }