<template>
  <div
    v-if="loaded"
    class="button-container"
  >
    <previous-button
      v-if="showPreviousButton"
      :button-label="previousButtonLabel"
      @click="previous"
    />
    <next-button
      v-if="showNextButton"
      :button-label="nextButtonLabel"
      :disabled="disableNextButton"
      @next="emitNext"
    />
  </div>
  <div v-else class="center-spinner">
    <ct-centered-spinner />
  </div>
</template>

<script>
export default {
  name: 'ButtonContainer',
  components: {
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner'),
    PreviousButton: () => import('@/components/StagelineV2/domainsFirst/shared/PreviousButton'),
    NextButton: () => import('@/components/StagelineV2/domainsFirst/shared/NextButton'),
  },
  props: {
    nextButtonLabel: {
      type: String,
      required: false,
      default: 'continue',
    },
    previousButtonLabel: {
      type: String,
      required: false,
      default: 'previous',
    },
    showNextButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    showPreviousButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    disableNextButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data () {
    return {
      loaded: false,
    }
  },
  async mounted () {
    this.loaded = true
  },
  methods: {
    emitNext() {
      this.$emit('next')
    },
    previous() {
      this.$emit('previous')
    },
  },
}
</script>

<style lang="scss" scoped>
.button-container {
  padding-bottom: 1.0em;
  @include sl__flex(row, flex-end, center);
  gap: 1.0em;
}

@media only screen and (max-width: 375px) {
  .button-container {
    flex-direction: column-reverse;
  }
}
</style>
